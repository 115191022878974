import GPX from 'ol/format/GPX';
import OSM from 'ol/source/OSM';
import {Map, View} from 'ol';
import VectorSource from 'ol/source/Vector';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {fromLonLat} from 'ol/proj';

const routes = {
    "route1": {
        name: "route1", center: [30.738186016136563, 46.55987037969479], zoom: 12.2, gpxFile: "route1.gpx",
    },
    "route2": {
        name: "route2", center: [30.736084618031494, 46.504419406517485], zoom: 10.2, gpxFile: "route2.gpx",
    },
    "route3": {
        name: "route3", center: [30.74546616618688, 46.4787530794004], zoom: 10.0, gpxFile: "route3.gpx",
    },
    "route4": {
        name: "route4", center: [30.766742367268876, 46.45752454627453], zoom: 9.4, gpxFile: "route4.gpx",
    },
    "route5": {
        name: "route5", center: [30.760814546487417, 46.39603743177492], zoom: 9.2, gpxFile: "route5.gpx",
    },
    "route6": {
        name: "route6", center: [30.64696577638267, 46.361530320302734], zoom: 9.1, gpxFile: "route6.gpx",
    },
}

const layerMap = {
    current: null
}
function getRoute(route) {
    return routes[route];
}

function getNewLayerWithGPX(gpxFilename) {
    let layer = new VectorLayer({
        source: new VectorSource({
            url: 'data/gpx/'+gpxFilename,
            format: new GPX(),
        }),
        style: function (feature) {
            return style[feature.getGeometry().getType()];
        },
    });
    if (layerMap.current === null) {
        layerMap.current = layer
    }
    return layer;
}

function changeRoute(nameRoute, routeZoom = -1) {
    let nRoute = getRoute(nameRoute);
    setNewRoute(nRoute.gpxFile);
    setCenterByRoute(nRoute.center, (routeZoom !== -1 ? routeZoom : nRoute.zoom));
}

function setNewRoute(gpxFilename) {
    let layer = getNewLayerWithGPX(gpxFilename);
    if (layerMap.current !== null) {
        map.removeLayer(layerMap.current);
    }
    map.addLayer(layer)
    layerMap.current = layer;
}

function setCenterByRoute(centerCoordinate, zoom = 10) {
    map.getView().setCenter(fromLonLat(centerCoordinate))
    map.getView().setZoom(zoom)
}

const style = {
    'Point': new Style({
        image: new CircleStyle({
            fill: new Fill({
                color: '#F56F23',
            }),
            radius: 5,
            stroke: new Stroke({
                color: '#F56F23',
                width: 1,
            }),
        }),
    }),
    'LineString': new Style({
        stroke: new Stroke({
            color: '#F56F23',
            width: 3,
        }),
    }),
    'MultiLineString': new Style({
        stroke: new Stroke({
            color: '#F56F23',
            width: 4,
        }),
    }),
};

const map = new Map({
    layers: [new TileLayer({
        source: new OSM({
            url: "https://{a-d}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png"
        })
    }), getNewLayerWithGPX(routes["route1"].gpxFile)],
    projection: 'EPSG:4326',
    target: document.getElementById('map'),
    view: new View({
        center: fromLonLat(routes["route1"].center),
        zoom: routes["route1"].zoom,
    }),
});

const chRoutes = document.querySelectorAll(".newRoute");
for (let i = 0; i < chRoutes.length; i++) {
    chRoutes[i].addEventListener("click", function (e) {
        let routeName = this.getAttribute("data-route-name");
        let routeZoom = parseFloat(this.getAttribute("data-route-zoom"));
        routeZoom = (isNaN(routeZoom) || routeZoom <= 0) ? -1 : routeZoom;
        changeRoute(routeName, routeZoom);
    })
}


